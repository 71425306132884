import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "send"
    }}>{`Send`}</h2>
    <p>{`To keep it as simple as possible we accept a GET request.`}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://api.anymessage.cloud/tts/initiate"
      }}>{`https://api.anymessage.cloud/tts/initiate`}</a></p>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`anyDir001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hz$o932`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sender of the call. Provided in international format without leading + or 00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520987654`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`recipient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call recipient. Provided in international format without leading + or 00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520123456`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Message you want to convert to a phone call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hi john, your code is 1 2 3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`voiceId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of AnyMessages voice Id (`}<a parentName="td" {...{
              "href": "#voice-id-list"
            }}>{`see table below`}</a>{`).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h3 {...{
      "id": "voice-id-list"
    }}>{`Voice id list`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`gender`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`language tag`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`arb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese Mandarin Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cmn-CN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Welsh Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cy-GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Danish Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`da-DK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Danish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`da-DK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`German Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`de-DE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`German Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`de-DE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian English Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-AU`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian English Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-AU`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`British English Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Welsh English Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-GB-WLS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indian English Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-IN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`US English Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-US`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`US English Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en-US`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Castilian Spanish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es-ES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Castilian Spanish Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es-ES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mexican Spanish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es-MX`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`US Spanish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es-US`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`US Spanish Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`es-US`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Canadian French Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-FR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fr-FR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icelandic Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`is-IS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icelandic Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`is-IS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italian Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`it-IT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italian Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`it-IT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ja-JP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ja-JP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Korean Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ko-KR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Norwegian Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nb-NO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-NL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nl-NL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pl-PL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`35`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pl-PL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brazilian Portuguese Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brazilian Portuguese Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Portuguese Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-PT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Portuguese Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pt-PT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Romanian Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ro-RO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`41`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian Male HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Male`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ru-RU`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ru-RU`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swedish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sv-SE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`44`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Turkish Female HD Voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Female`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tr-TR`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "request-examples"
    }}>{`Request examples`}</h3>
    <p>{`The following examples fulfill this scenario: `}</p>
    <ol>
      <li parentName="ol">{`You want to send a TTS call to your german friend. His msisdn equals 0049 1520 12345678`}</li>
      <li parentName="ol">{`You want your own number as TTS calls source address. Your number is +49 1520 98765432`}</li>
      <li parentName="ol">{`The TTS call should contain the following message "Hi, I hope you are all fine. Wish you all the best John."`}</li>
      <li parentName="ol">{`You want an us english male speaker.`}</li>
      <li parentName="ol">{`AnyMessage provided you the following credentials username = anyDir001 and password = hz7r§j`}</li>
    </ol>
    <h4 {...{
      "id": "shell"
    }}>{`Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
curl -G -i \\
    --data-urlencode "username=anyDir001" \\
    --data-urlencode "password=hz7r§j" \\
    --data-urlencode "sender=49152098765432" \\
    --data-urlencode "recipient=49152012345678" \\
    --data-urlencode "content=Hi, I hope you are all fine. Wish you all the best John." \\
    --data-urlencode "voiceId=15" \\
    https://api.anymessage.cloud/sms/initiate
`}</code></pre>
    <p>{`Please note`}</p>
    <ul>
      <li parentName="ul">{`The recipient is 49152012345678. The leading 00 is removed`}</li>
      <li parentName="ul">{`The sender is 49152098765432. The leading + is removed`}</li>
    </ul>
    <h4 {...{
      "id": "php"
    }}>{`PHP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php

$url = 'https://api.anymessage.cloud/sms/initiate?';

$username = 'anyDir001'; 
$password = 'hz7r§j'; 
$sender = '49152098765432'; // NOTE the leading + of sender is truncated
$recipient = '49152012345678'; // NOTE the leading 00 of recipient is truncated
$content = 'Hi, I hope you are all fine. Wish you all the best John.';   
$voiceId = 15;   


$parameter = array(
    'username'=>$username,
    'password'=>$password,
    'sender'=>$sender,
    'recipient'=>$recipient,
    'content'=>$content,
    'voiceId'=>$voiceId,
);

$url .= http_build_query($parameter);
    

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);

$response = curl_exec($ch);

// Response evaluation 
if($response === false){
    var_dump(curl_error($ch));
}else{
    var_dump($response);
}
?>
`}</code></pre>
    <h3 {...{
      "id": "request-responses"
    }}>{`Request responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`You Receive a HTTP status code of 200 and a json formatted message containing a message Id.`}</p>
    <p>{`A sample success response looks like this.`}</p>
    <pre><code parentName="pre" {...{}}>{`> {"msgId":"tts-896ba6c8-9013-47aa-82c6-7e01707c7c72"}
`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <pre><code parentName="pre" {...{}}>{`| HTTP status Code | Http response                                    | description                                              |
| ---------------- | ------------------------------------------------ | -------------------------------------------------------- |
| 400              | {"message":"Not all params specified!"}          | The request does not contain all mandatory GET parameter |
| 400              | {"message":"recipient needs to be numeric only"} | The provided recipient contains non digit characters     |
| 400              | {"message":"sender needs to be numeric only"}    | The provided sender contains non digit characters        |
| 400              | {"message":"invalid voiceId"}                    | The provided VoiceId is invalid                          |
| 401              | {"message":"Invalid credentials"}                | The provided credentials are not valid                   |
| 403              | {"message":"Action forbidden"}                   | Your account is not authorized to use this endpoint      |
| 429              | {"message":"Limits reached"}                     | You Limit for TTS calls is exceeded                      |
| 500              | {"message":"Internal Error"}                     | An internal error occurred                               |
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      